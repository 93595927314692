import React, { Component } from 'react';
import SecurityPrompt from './component/securityPrompt'
import GaugeChart1Green from './component/gauge1Green'
import GaugeChart1White from './component/gauge1White'
import GaugeChart1Red from './component/gauge1Red'
import GaugeChart2Green from './component/gauge2Green'
import GaugeChart2White from './component/gauge2White'
import GaugeChart2Red from './component/gauge2Red'
import GaugeChart3 from './component/gauge3'
import GaugeChart4 from './component/gauge4'
import CurrentSmartService from './component/currentSmartService'
import CurrentSmartProduct from './component/currentSmartProduct'
import SmartServiceOnly from './component/smartServiceOnly'
import SmartProductOnly from './component/smartProductOnly'
import Loader from './Radio-1s-800px.svg'
import Logo from './dtbtlogo.png'

const securityCodeURL = 'https://dashboard-server.truvisory.com/security-code'
const securityCodeCheckURL = 'https://dashboard-server.truvisory.com/security-code-check'
const googleSheetDataURL = 'https://dashboard-server.truvisory.com/google-sheet-data'
const zenotiURL = 'https://dashboard-server.truvisory.com/zenoti-report-data'
const company = 'dothebangthingsalon'

class App extends Component {
  constructor(){
    super()
      this.state = {
        randomCode: '',
        timer: 0,
        screenFlip: true,
        screen1: '',
        screen2: 'd-none',
        height: window.innerHeight,
        width: window.innerWidth,
        heightHalf: window.innerHeight/2,
        widthHalf: window.innerWidth/2,
        currentService: 0,
        serviceSmartGoal: 0,
        serviceColor: '#ffffff',
        currentProduct: 0,
        productSmartGoal: 0,
        productColor: '#ffffff',
        series1: [0, 0],
        series1Updated: [0, 0],
        chart1CurrentService: 0,
        chart1ZenotiPlusGoogle: 0,
        chart1ServiceGoal: 0,
        currentMonthlyServiceGoalUnEdited: 0,
        chart1PercentGoal: 0,
        chart1ZenotiPlusGooglePercent: 0,
        monthServiceColor: '#ffffff',
        series2: [0, 0],
        series2Updated: [0, 0],
        chart2CurrentProduct: 0,
        chart2ZenotiPlusGoogle: 0,
        chart2ProductGoal: 0,
        chart2PercentGoal: 0,
        chart2ZenotiPlusGooglePercent: 0,
        monthProductColor: '#ffffff',
        kDisplayChart1: '',
        options: {
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90}}},
        options2: {
          chart: {
            toolbar: {
              show: false}},
          colors: ['#2a2a2a'],
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              hollow: {
                margin: 0,
                size: '65%',
                background: '#2a2a2a',
                position: 'front',},
              track: {
                background: '#2a2a2a',
                strokeWidth: '80%',
                margin: 0,},
              dataLabels: {
                value: {
                  formatter: function (val) {
                    return val + '%';},
                  color: '#ffffff',
                  fontSize: '11em',
                  fontFamily: 'Montserrat, sans-serif',
                  show: true,}}}},
          fill: {type: 'solid',},
          stroke: {
            lineCap: 'round'},
          labels: [''],},
        series3: [0],
        options3: {
          chart: {
            toolbar: {
              show: false}},
          colors: ['#2a2a2a'],
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              hollow: {
                margin: 0,
                size: '65%',
                background: '#2a2a2a',
                position: 'front',},
              track: {
                background: '#2a2a2a',
                strokeWidth: '80%',
                margin: 0,},
              dataLabels: {
                value: {
                  formatter: function (val) {
                    return val + '%';},
                  color: '#ffffff',
                  fontSize: '11em',
                  fontFamily: 'Montserrat, sans-serif',
                  show: true,}}}},
          fill: {type: 'solid',},
          stroke: {lineCap: 'round'},
          labels: [''],},
        series4: [0],
        googleAPI: {},
        zenotiAPI: {},
        zenotiServices: [],
        zenotiProducts: [],
        zenotiHappinessPercent: 0}   
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    if(localStorage.Authenticated !== localStorage.RandomCode || !localStorage.RandomCode) {
      localStorage.setItem('RandomCode', false)
      this.genRandomCode()
    }
    this.updateDashboard()
    this.zenotiSalesData()
    setInterval(() => this.updateDashboard(), 1800000)
    setInterval(() => this.zenotiSalesData(), 900000)
    setInterval(() => this.progressBar(), 1000)
    setInterval(() => this.refreshBrowser(), 3600000)
  }

  updateDimensions = () => {
    this.setState({
      height: window.innerHeight, 
      width: window.innerWidth,
      heightHalf: window.innerHeight/2,
      widthHalf: window.innerWidth/2})
  }

  genRandomCode = () => {
    localStorage.setItem('RandomCode', Math.random().toString(36).substr(2,6).toUpperCase())
    this.setState({randomCode: localStorage.RandomCode})
    this.sendText(localStorage.RandomCode)
    this.checkSecurityCode()
  }

  sendText = async (randomCodeFromStorage) => {
    await fetch(securityCodeURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'},
      body: JSON.stringify({
        username: company,
        code: randomCodeFromStorage})})
  }

  checkSecurityCode = async () => this.interval = setInterval(() => this.getData(), 3000)

  getData = async () => {
    if(localStorage.Authenticated !== localStorage.RandomCode) {
      const response = await fetch(securityCodeCheckURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'},
        body: JSON.stringify({username: company})})
      const json = await response.json()
      localStorage.setItem('Authenticated', json.authenticate[0].code)
      this.setState({authenticated: json.authenticate[0].code})
    }
    else {
      this.updateDashboard()
      this.zenotiSalesData()
      clearInterval(this.interval)}
  }

  updateDashboard = () => {
    if(localStorage.Authenticated === localStorage.RandomCode) {
      this.getGoogleData()
    }
  }

  getGoogleData = async () => {
    const response = await fetch(googleSheetDataURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'},
      body: JSON.stringify({username: company})})
    const json = await response.json()
    this.setState({googleAPI: json}, () => this.setGoogleData())
  }

  setGoogleData = () => {
    const monthlyServicePercent = ((((this.state.googleAPI.values[1][0]*1))/this.state.googleAPI.values[0][0])*100).toFixed()
    const monthlyProductPercent = ((((this.state.googleAPI.values[1][1]*1))/this.state.googleAPI.values[0][1])*100).toFixed()
    const monthlyPreBookPercent = this.state.googleAPI.values[0][6]
    let serviceSmartGoalValue = 0
      if(this.state.googleAPI.values[3][3] === '#VALUE!' || this.state.googleAPI.values[3][3] === '#N/A') serviceSmartGoalValue = 0
      else{ serviceSmartGoalValue = (this.state.googleAPI.values[3][3] *1)}
    let productSmartGoalValue = 0
      if(this.state.googleAPI.values[3][4] === '#VALUE!' || this.state.googleAPI.values[3][4] === '#N/A') productSmartGoalValue = 0
      else{ productSmartGoalValue = (this.state.googleAPI.values[3][4] *1)}
    const series1OuterGraph = (((this.state.googleAPI.values[2][0] *1) + (serviceSmartGoalValue *1)) / (this.state.googleAPI.values[0][0] *1)) *100
    const series1InnerGraph = ((this.state.googleAPI.values[1][0] *1) / (this.state.googleAPI.values[0][0] *1)) *100
    const series2OuterGraph = (((this.state.googleAPI.values[2][1] *1) + (productSmartGoalValue *1)) / (this.state.googleAPI.values[0][1] *1)) *100
    const series2InnerGraph = ((this.state.googleAPI.values[1][1] *1) / (this.state.googleAPI.values[0][1] *1)) *100
    const currentMonthlyProductGoal = ((this.state.googleAPI.values[1][1] *1))
    const monthlyProductGoal = (this.state.googleAPI.values[0][1] *1)
    const currentMonthlyServiceGoalUnEdited = this.state.googleAPI.values[1][0]*1
    let currentMonthlyServiceGoal = 0
      if(this.state.googleAPI.values[1][0]*1 < 1000) {
        currentMonthlyServiceGoal = (this.state.googleAPI.values[1][0] *1) 
        this.setState({kDisplayChart1: "d-none"})}
      else {
        currentMonthlyServiceGoal = (((this.state.googleAPI.values[1][0] *1))/1000).toFixed(1)
        this.setState({kDisplayChart1: ""})}
    let monthlyServiceGoal = 0
      if(!(this.state.googleAPI.values[0][0]*1 % 1000)) {
        monthlyServiceGoal = ((this.state.googleAPI.values[0][0] *1)/1000)
      }
      else {
        monthlyServiceGoal = ((this.state.googleAPI.values[0][0] *1)/1000).toFixed(1)}
    this.setState({
      serviceSmartGoal: serviceSmartGoalValue,
      productSmartGoal: productSmartGoalValue,
      chart1CurrentService: currentMonthlyServiceGoal,
      chart1ServiceGoal: monthlyServiceGoal,
      currentMonthlyServiceGoalUnEdited: currentMonthlyServiceGoalUnEdited,
      chart1PercentGoal: monthlyServicePercent,
      chart2CurrentProduct: currentMonthlyProductGoal,
      chart2ProductGoal: monthlyProductGoal,
      chart2PercentGoal: monthlyProductPercent,
      series1: [series1OuterGraph, series1InnerGraph],
      series2: [series2OuterGraph, series2InnerGraph],
      series4: [monthlyPreBookPercent *1]
    }, () => this.combineZenotiandGoogleData())
  }

  zenotiSalesData = () => {
    if(localStorage.Authenticated === localStorage.RandomCode) {
      this.getZenotiReportData()
    }
  }

  getZenotiReportData = async () => {
    const d = new Date()
    const year = d.getFullYear().toString()
    let month = (d.getMonth() +1).toString()
    let day = d.getDate().toString()
    if (month < 10 ) month = `0${month}`
    if (day < 10 ) day = `0${day}`
    const response = await fetch(zenotiURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'},
      body: JSON.stringify({
        username: company,
        year: year,
        month: month,
        day: day})})
    const json = await response.json()
    this.setState({zenotiAPI: json}, () => this.zenotiCalculations())
  }

  zenotiCalculations = () => {
    this.setState({
      zenotiServices: this.state.zenotiAPI.center_sales_report.filter(service => service.item.type === 0),
      zenotiProducts: this.state.zenotiAPI.center_sales_report.filter(product => product.item.type === 2)} , () => {
        this.setZenotiServiceData()
        this.setZenotiProductData()
      })
  }

  setZenotiServiceData = () => {
    this.state.zenotiServices.length === 0
    ? this.noZenotiServiceData()
    : this.state.zenotiServices.length === 1
      ? Promise.all([this.oneZenotiServiceData(), this.zenotiHappinessCalculation()])
      : Promise.all([this.manyZenotiServiceData(), this.zenotiHappinessCalculation()])
  }

  setZenotiProductData = () => {
    this.state.zenotiProducts.length === 0
    ? this.noZenotiProductData()
    : this.state.zenotiProducts.length === 1
      ? this.oneZenotiProductData()
      : this.manyZenotiProductData()
  }

  noZenotiServiceData = () => this.setState({currentService: 0, zenotiHappinessPercent: 0}, () => {this.zenotiHappinessCalculation()})
  noZenotiProductData = () => this.setState({currentProduct: 0})

  oneZenotiServiceData = () => {
    const totalServiceSalesToday = this.state.zenotiServices[0].final_sale_price.toFixed()
    this.setState({currentService: totalServiceSalesToday}, () => this.combineZenotiandGoogleData())
  }
  oneZenotiProductData = () => {
    const totalProductSalesToday = (this.state.zenotiProducts[0].unit_price - this.state.zenotiProducts[0].discount).toFixed()
    this.setState({currentProduct: totalProductSalesToday})
  }

  manyZenotiServiceData = () => {
    const totalServiceSalesToday = this.state.zenotiServices.map(data => data.final_sale_price).reduce((acc, cur) => acc + cur).toFixed()
    this.setState({currentService: totalServiceSalesToday}, () => this.combineZenotiandGoogleData())
  }
  manyZenotiProductData = () => {
    const totalProductSalesToday = this.state.zenotiProducts.map(data => data.unit_price - data.discount).reduce((acc, cur) => acc + cur).toFixed()
    this.setState({currentProduct: totalProductSalesToday})
  }

  progressBar = () => {
    if(localStorage.Authenticated === localStorage.RandomCode) {
      if (this.state.timer < 30) this.setState({timer: this.state.timer + 1})
      else {this.setState({timer: 0, screenFlip: !this.state.screenFlip})}
    this.changeScreen()
    }
  }

  zenotiHappinessCalculation = () => {
    const onlyServices = this.state.zenotiServices.filter(service => service.item.code !== "HAP01").map(clients => clients.guest.guest_id)
    const onlyUniqueClients = Array.from(new Set(onlyServices)).length
    const onlyHappiness = this.state.zenotiServices.filter(service => service.item.code === "HAP01").length
    let todaysHappinessPercent = 0
    onlyUniqueClients !== 0
      ? todaysHappinessPercent = (((onlyHappiness / onlyUniqueClients) * 100).toFixed() *1)
      : todaysHappinessPercent = 0
    this.setState({zenotiHappinessPercent: todaysHappinessPercent}, () => this.zenotiHappiness())
  }

  zenotiHappiness = () => {
    const todaysHappinessPercent = this.state.zenotiHappinessPercent
    this.setState({series3: [todaysHappinessPercent]}, () => this.updateColorOnMetGoal())
  }

  combineZenotiandGoogleData = () => {
    let currentMonthlyServiceGoal = 0
    let updatedChart1Percent = 0
      if((this.state.currentMonthlyServiceGoalUnEdited + (this.state.currentService *1)) < 1000) {
        currentMonthlyServiceGoal = (this.state.currentMonthlyServiceGoalUnEdited + (this.state.currentService *1)) 
        updatedChart1Percent = ((currentMonthlyServiceGoal * 1)/(this.state.chart1ServiceGoal * 1000)*100)
        this.setState({kDisplayChart1: "d-none"})
      }
      else {
        currentMonthlyServiceGoal = (((this.state.currentMonthlyServiceGoalUnEdited + (this.state.currentService *1)))/1000).toFixed(1)
        updatedChart1Percent = ((currentMonthlyServiceGoal * 1)/(this.state.chart1ServiceGoal * 1)*100)
        this.setState({kDisplayChart1: ""})
      }
    const updatedChart1PercentFixed = updatedChart1Percent.toFixed()
    const series1UpdatedArray = [this.state.series1[0], updatedChart1Percent *1]
    const updatedChart2Product = (this.state.currentProduct *1) + (this.state.chart2CurrentProduct *1)
    const updatedChart2Percent = (((updatedChart2Product/this.state.chart2ProductGoal)*100))
    const updatedChart2PercentFixed = updatedChart2Percent.toFixed()
    const series2UpdatedArray = [this.state.series2[0], updatedChart2Percent]
    this.setState({
      chart1ZenotiPlusGoogle: currentMonthlyServiceGoal,
      chart1ZenotiPlusGooglePercent: updatedChart1PercentFixed,
      series1Updated: series1UpdatedArray,
      chart2ZenotiPlusGoogle: updatedChart2Product,
      chart2ZenotiPlusGooglePercent: updatedChart2PercentFixed,
      series2Updated: series2UpdatedArray}, () => this.updateColorOnMetGoal())
  }

  updateColorOnMetGoal = () => {
    this.state.currentService *1 >= this.state.serviceSmartGoal *1
      ? this.setState({serviceColor: '#8cb34b'})
      : this.setState({serviceColor: '#ffffff'})
    this.state.currentProduct *1 >= this.state.productSmartGoal *1
      ? this.setState({productColor: '#8cb34b'})
      : this.setState({productColor: '#ffffff'})
    this.state.chart1ZenotiPlusGooglePercent *1 >= 100
      ? this.setState({monthServiceColor: '#8cb34b'})
      : this.setState({monthServiceColor: '#ffffff'})
    this.state.chart2ZenotiPlusGooglePercent *1 >= 100
      ? this.setState({monthProductColor: '#8cb34b'})
      : this.setState({monthProductColor: '#ffffff'})
    this.state.series3[0] *1 === 0
      ? this.setState({options2: {
        chart: {
          toolbar: {
            show: false}},
        colors: ['#2a2a2a'],
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: '65%',
              background: '#2a2a2a',
              position: 'front',},
            track: {
              background: '#2a2a2a',
              strokeWidth: '80%',
              margin: 0,},
            dataLabels: {
              value: {
                formatter: function (val) {
                  return val + '%';},
                color: '#ffffff',
                fontSize: '11em',
                fontFamily: 'Montserrat, sans-serif',
                show: true,}}}},
        fill: {type: 'solid',},
        stroke: {
          lineCap: 'round'},
        labels: [''],},})
      : this.state.series3[0] *1 < 60
        ? this.setState({options2: {
          chart: {
            toolbar: {
              show: false}},
          colors: ['#a44545'],
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              hollow: {
                margin: 0,
                size: '65%',
                background: '#2a2a2a',
                position: 'front',},
              track: {
                background: '#2a2a2a',
                strokeWidth: '80%',
                margin: 0,},
              dataLabels: {
                value: {
                  formatter: function (val) {
                    return val + '%';},
                  color: '#ffffff',
                  fontSize: '11em',
                  fontFamily: 'Montserrat, sans-serif',
                  show: true,}}}},
          fill: {type: 'solid',},
          stroke: {
            lineCap: 'round'},
          labels: [''],},})
        : this.state.series3[0] *1 < 75
          ? this.setState({options2: {
            chart: {
              toolbar: {
                show: false}},
            colors: ['#ffffff'],
            plotOptions: {
              radialBar: {
                startAngle: -135,
                endAngle: 225,
                hollow: {
                  margin: 0,
                  size: '65%',
                  background: '#2a2a2a',
                  position: 'front',},
                track: {
                  background: '#2a2a2a',
                  strokeWidth: '80%',
                  margin: 0,},
                dataLabels: {
                  value: {
                    formatter: function (val) {
                      return val + '%';},
                    color: '#ffffff',
                    fontSize: '11em',
                    fontFamily: 'Montserrat, sans-serif',
                    show: true,}}}},
            fill: {type: 'solid',},
            stroke: {
              lineCap: 'round'},
            labels: [''],},})
          : this.setState({options2: {
            chart: {
              toolbar: {
                show: false}},
            colors: ['#8cb34b'],
            plotOptions: {
              radialBar: {
                startAngle: -135,
                endAngle: 225,
                hollow: {
                  margin: 0,
                  size: '65%',
                  background: '#2a2a2a',
                  position: 'front',},
                track: {
                  background: '#2a2a2a',
                  strokeWidth: '80%',
                  margin: 0,},
                dataLabels: {
                  value: {
                    formatter: function (val) {
                      return val + '%';},
                    color: '#ffffff',
                    fontSize: '11em',
                    fontFamily: 'Montserrat, sans-serif',
                    show: true,}}}},
            fill: {type: 'solid',},
            stroke: {
              lineCap: 'round'},
            labels: [''],},})  
    this.state.series4[0] *1 === 0
      ? this.setState({options3: {
        chart: {
          toolbar: {
            show: false}},
        colors: ['#2a2a2a'],
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: '65%',
              background: '#2a2a2a',
              position: 'front',},
            track: {
              background: '#2a2a2a',
              strokeWidth: '80%',
              margin: 0,},
            dataLabels: {
              value: {
                formatter: function (val) {
                  return val + '%';},
                color: '#ffffff',
                fontSize: '11em',
                fontFamily: 'Montserrat, sans-serif',
                show: true,}}}},
        fill: {type: 'solid',},
        stroke: {lineCap: 'round'},
        labels: [''],},})
      : this.state.series4[0] *1 < 32
        ? this.setState({options3: {
          chart: {
            toolbar: {
              show: false}},
          colors: ['#a44545'],
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              hollow: {
                margin: 0,
                size: '65%',
                background: '#2a2a2a',
                position: 'front',},
              track: {
                background: '#2a2a2a',
                strokeWidth: '80%',
                margin: 0,},
              dataLabels: {
                value: {
                  formatter: function (val) {
                    return val + '%';},
                  color: '#ffffff',
                  fontSize: '11em',
                  fontFamily: 'Montserrat, sans-serif',
                  show: true,}}}},
          fill: {type: 'solid',},
          stroke: {lineCap: 'round'},
          labels: [''],},})
        : this.state.series4[0] *1 < 40
          ? this.setState({options3: {
            chart: {
              toolbar: {
                show: false}},
            colors: ['#ffffff'],
            plotOptions: {
              radialBar: {
                startAngle: -135,
                endAngle: 225,
                hollow: {
                  margin: 0,
                  size: '65%',
                  background: '#2a2a2a',
                  position: 'front',},
                track: {
                  background: '#2a2a2a',
                  strokeWidth: '80%',
                  margin: 0,},
                dataLabels: {
                  value: {
                    formatter: function (val) {
                      return val + '%';},
                    color: '#ffffff',
                    fontSize: '11em',
                    fontFamily: 'Montserrat, sans-serif',
                    show: true,}}}},
            fill: {type: 'solid',},
            stroke: {lineCap: 'round'},
            labels: [''],},})
          : this.setState({options3: {
            chart: {
              toolbar: {
                show: false}},
            colors: ['#8cb34b'],
            plotOptions: {
              radialBar: {
                startAngle: -135,
                endAngle: 225,
                hollow: {
                  margin: 0,
                  size: '65%',
                  background: '#2a2a2a',
                  position: 'front',},
                track: {
                  background: '#2a2a2a',
                  strokeWidth: '80%',
                  margin: 0,},
                dataLabels: {
                  value: {
                    formatter: function (val) {
                      return val + '%';},
                    color: '#ffffff',
                    fontSize: '11em',
                    fontFamily: 'Montserrat, sans-serif',
                    show: true,}}}},
            fill: {type: 'solid',},
            stroke: {lineCap: 'round'},
            labels: [''],},})
  }

  changeScreen = () => {
    this.state.screenFlip
      ? this.setState({screen1: '', screen2: 'd-none'})
      : this.setState({screen1: 'd-none', screen2: ''})
  }

  refreshBrowser = () => {
    const d = new Date()
    const n = d.getHours()
    if(localStorage.Authenticated === localStorage.RandomCode && n === 23) setTimeout(() => window.location.reload(), 3601000)
  }

  render() {
    return (
      <div>
        {localStorage.Authenticated === localStorage.RandomCode
          ? <div> 
            {this.state.chart1ServiceGoal !==0 
              ?<div className={`container-fluid ${this.state.screen1}`}>
                <div className="row" style={{height: this.state.heightHalf}}>
                  {this.state.currentService === 0
                    ? <SmartServiceOnly 
                        heightHalf={this.state.heightHalf} 
                        serviceColor={this.state.serviceColor} 
                        serviceSmartGoal={this.state.serviceSmartGoal} />
                    : <CurrentSmartService 
                        heightHalf={this.state.heightHalf} 
                        serviceColor={this.state.serviceColor}
                        currentService={this.state.currentService}
                        serviceSmartGoal={this.state.serviceSmartGoal} />}
                  {this.state.currentProduct === 0
                    ? <SmartProductOnly 
                        heightHalf={this.state.heightHalf} 
                        productColor={this.state.productColor} 
                        productSmartGoal={this.state.productSmartGoal}/>
                    : <CurrentSmartProduct 
                        heightHalf={this.state.heightHalf}
                        productColor={this.state.productColor} 
                        currentProduct={this.state.currentProduct}
                        productSmartGoal={this.state.productSmartGoal} />}
                </div>
                <div className="row" style={{height: this.state.heightHalf}}>
                  <div className="col-6 p-0" style={{height: this.state.heightHalf}}>
                  {this.state.series1Updated[1] >= this.state.series1Updated[0]
                      ? <GaugeChart1Green
                          options={this.state.options}
                          series={this.state.series1Updated}
                          height={this.state.height * 13/16}
                          width={this.state.widthHalf} />
                      : this.state.series1Updated[1] < this.state.series1Updated[0] - 5
                        ? <GaugeChart1Red
                            options={this.state.options}
                            series={this.state.series1Updated}
                            height={this.state.height * 13/16}
                            width={this.state.widthHalf} />
                        : <GaugeChart1White
                            options={this.state.options}
                            series={this.state.series1Updated}
                            height={this.state.height * 13/16}
                            width={this.state.widthHalf} />}
                  </div>
                  <div className="col-6 p-0" style={{height: this.state.heightHalf}}>
                    {this.state.series2Updated[1] >= this.state.series2Updated[0]
                      ? <GaugeChart2Green
                          options={this.state.options}
                          series={this.state.series2Updated}
                          height={this.state.height * 13/16}
                          width={this.state.widthHalf} />
                      : this.state.series2Updated[1] < this.state.series2Updated[0] - 5
                        ? <GaugeChart2Red
                            options={this.state.options}
                            series={this.state.series2Updated}
                            height={this.state.height * 13/16}
                            width={this.state.widthHalf} />
                        : <GaugeChart2White
                            options={this.state.options}
                            series={this.state.series2Updated}
                            height={this.state.height * 13/16}
                            width={this.state.widthHalf} />}                
                  </div>
                </div>
              </div>
              : <div className="text-center">
                  <img src={Loader} alt="loader" id="loadingImage"></img>
                  <p style={{fontSize: this.state.heightHalf/12}}>Your dashboard is on its way!</p>
                </div>}
              {this.state.chart1ServiceGoal !==0 
              ? <div className={`container-fluid fixed ${this.state.screen1}`}>
                <div className="row" style={{height: this.state.heightHalf}}>
                  {/* Screen 1 Layer 1 Top Left Row */}
                  <div className="col-6 p-0" style={{height: this.state.heightHalf}}>
                  </div>
                  {/* Screen 1 Layer 1 Top Right Row */}
                  <div className="col-6 p-0" style={{height: this.state.heightHalf}}>
                  </div>
                </div>
                <div className="row" style={{height: this.state.heightHalf}}>
                  {/* Screen 1 Layer 1 Bottom Left Row */}
                  <div className="col-1 p-0" style={{height: this.state.heightHalf,}}>
                  </div>
                  <div className="col-1 p-0" style={{height: this.state.heightHalf}}>
                    <p
                      className="text-center"
                      style={{paddingTop: this.state.heightHalf *13/16, fontSize: this.state.heightHalf/12, color: `${this.state.monthServiceColor}`}}>
                      {this.state.chart1ZenotiPlusGooglePercent}<span style={{fontSize: (this.state.heightHalf/12)*.61}}>%</span></p>
                  </div>
                  <div className="col-2 p-0" style={{height: this.state.heightHalf}}>
                    <p 
                      className="text-center" 
                      style={{paddingTop: this.state.heightHalf *10/16, fontSize: this.state.heightHalf/5.5, color: `${this.state.monthServiceColor}`}}>
                      <span style={{fontSize: (this.state.heightHalf/5.5)*.61}}>$</span>
                      {this.state.chart1ZenotiPlusGoogle}
                      <span className={this.state.kDisplayChart1} style={{fontSize: (this.state.heightHalf/5.5)*.61}}>k</span></p>
                  </div>
                  <div className="col-1 p-0" style={{height: this.state.heightHalf}}>
                    <p
                      className="text-right"
                      style={{paddingTop: this.state.heightHalf *13/16, fontSize: this.state.heightHalf/12, color: `${this.state.monthServiceColor}`}}>
                      <span style={{fontSize: (this.state.heightHalf/12)*.61}}>$</span>{this.state.chart1ServiceGoal}<span style={{fontSize: (this.state.heightHalf/12)*.61}}>k</span></p>
                  </div>
                  <div className="col-1 p-0" style={{height: this.state.heightHalf}}>
                  </div>
                  {/* Screen 1 Layer 1 Bottom Right Row */}
                  <div className="col-1 p-0" style={{height: this.state.heightHalf}}>
                  </div>
                  <div className="col-1 p-0" style={{height: this.state.heightHalf}}>
                    <p
                      className="text-center"
                      style={{paddingTop: this.state.heightHalf *13/16, fontSize: this.state.heightHalf/12, color: `${this.state.monthProductColor}`}}>
                      {this.state.chart2ZenotiPlusGooglePercent}<span style={{fontSize: (this.state.heightHalf/12)*.61}}>%</span></p>
                  </div>
                  <div className="col-2 p-0" style={{height: this.state.heightHalf}}>
                    <p 
                      className="text-center" 
                      style={{paddingTop: this.state.heightHalf *10/16, fontSize: this.state.heightHalf/5.5, color: `${this.state.monthProductColor}`}}>
                      <span style={{fontSize: (this.state.heightHalf/5.5)*.61}}>$</span>
                      {this.state.chart2ZenotiPlusGoogle.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                  </div>
                  <div className="col-1 p-0" style={{height: this.state.heightHalf}}>
                    <p
                      className="text-right"
                      style={{paddingTop: this.state.heightHalf *13/16, fontSize: this.state.heightHalf/12, color: `${this.state.monthProductColor}`}}>
                      <span style={{fontSize: (this.state.heightHalf/12)*.61}}>$</span>{this.state.chart2ProductGoal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                  </div>
                  <div className="col-1 p-0" style={{height: this.state.heightHalf}}>
                  </div>
                </div>
              </div>
              : <div></div>}
              {this.state.chart1ServiceGoal !==0 
              ? <div className={`container-fluid fixed ${this.state.screen1}`}>
                <div className="row" style={{height: this.state.heightHalf}}>
                  {/* Screen 1 Layer 2 Top Left Row */}
                  <div className="col-6 p-0" style={{height: this.state.heightHalf}}>
                    <p 
                      className="text-center"
                      style={{paddingTop: this.state.heightHalf/20, fontSize: this.state.heightHalf/18, color: `${this.state.serviceColor}`}}>TODAY'S SERVICE GOAL</p>
                  </div>
                  {/* Screen 1 Layer 2 Top Right Row */}
                  <div className="col-6 p-0" style={{height: this.state.heightHalf}}>
                    <p 
                      className="text-center"
                      style={{paddingTop: this.state.heightHalf/20, fontSize: this.state.heightHalf/18, color: `${this.state.productColor}`}}>TODAY'S PRODUCT GOAL</p>
                  </div>
                </div>
                <div className="row" style={{height: this.state.heightHalf}}>
                  {/* Screen 1 Layer 2 Bottom Left Row */}
                  <div className="col-6 p-0" style={{height: this.state.heightHalf}}>
                    <p 
                      className="text-center" 
                      style={{paddingTop: this.state.heightHalf/20, fontSize: this.state.heightHalf/18, color: `${this.state.monthServiceColor}`}}>THIS MONTH'S SERVICE GOAL</p>
                  </div>
                  {/* Screen 1 Layer 2 Bottom Right Row */}
                  <div className="col-6 p-0" style={{height: this.state.heightHalf}}>
                    <p 
                      className="text-center"
                      style={{paddingTop: this.state.heightHalf/20, fontSize: this.state.heightHalf/18, color: `${this.state.monthProductColor}`}}>THIS MONTH'S PRODUCT GOAL</p>
                  </div>
                </div>
              </div>
              : <div></div>}
              {this.state.chart1ServiceGoal !==0
              ? <div className={`container-fluid fixed ${this.state.screen2}`}>
                <div className="row" style={{height: this.state.height, paddingTop: this.state.heightHalf/20}}>
                  {/* Screen 2 Layer 2 Left Row */}
                  <div className="col-6 p-0" style={{height: this.state.height}}>
                    <p 
                      className="text-center"
                      style={{paddingTop: this.state.heightHalf/20, marginBottom: -30, fontSize: this.state.heightHalf/18, color: "#ffffff"}}>% OF CLIENTS<br></br>HAPPINESS SYSTEM UTILIZED TODAY</p>
                        <GaugeChart3
                          options={this.state.options2}
                          series={this.state.series3}
                          height={this.state.height}
                          width={this.state.widthHalf} />
                  </div>
                  {/* Screen 2 Layer 2 Right Row */}
                  <div className="col-6 p-0" style={{height: this.state.height}}>
                    <p 
                      className="text-center"
                      style={{paddingTop: this.state.heightHalf/20, marginBottom: -30, fontSize: this.state.heightHalf/18, color: "#ffffff"}}>% OF CLIENTS<br></br>PREBOOKING THIS MONTH</p>
                        <GaugeChart4
                          options={this.state.options3}
                          series={this.state.series4}
                          height={this.state.height} 
                          width={this.state.widthHalf} />
                  </div>
                </div>
              </div>
              : <div></div>}
              {this.state.chart1ServiceGoal !==0 
              ? <div className="container-fluid fixed">
                <div className="row" style={{height: this.state.height}}>
                  <div className="col-12 p-0" style={{height: this.state.height}}>
                  <img id="logo" src={Logo} alt="logo" style={{width: this.state.widthHalf/6, paddingTop: this.state.heightHalf/1.2}}></img>
                  </div>
                </div>
              </div>
              : <div></div>}
              {this.state.chart1ServiceGoal !==0 
              ? <div className="container-fluid fixed">
                <div className="row" style={{height: this.state.height}}>
                  <div className="col-12 p-0" style={{height: this.state.height}}>
                    <div className="progress" style={{height: 1, marginTop: this.state.height - 1}}>
                      <div className="progress-bar bgWhite" role="progressbar" style={{width: `${(this.state.timer/30)*100}%`}} aria-valuenow={this.state.timer} aria-valuemin="0" aria-valuemax="30"></div>
                    </div>
                  </div>
                </div>
              </div>
              : <div></div>}
            </div>
        : <SecurityPrompt
            randomCode={this.state.randomCode} 
            height={this.state.heightHalf} />}
      </div>
    )
  }
}
export default App;