import React from 'react';
import ReactApexCharts from 'react-apexcharts'
import '../styles/graphGoalRed2.css'

const GaugeChart = (props) => {  
    return (
        <div id="chart2Red">
            <ReactApexCharts
                options={props.options}
                series={props.series} 
                type="radialBar" 
                height={props.height} 
                width={props.width}/>
        </div>
    )
}

export default GaugeChart