import React from 'react';
import ReactApexCharts from 'react-apexcharts'

const Gauge4 = (props) => {  
  return (
    <div>
      <ReactApexCharts 
        options={props.options}
        series={props.series}
        type="radialBar"
        height={props.height * 15/18}
        width={props.width} />
    </div>
  )
}

export default Gauge4