import React from 'react';

const CurrentSmartService = (props) => {  
  return (
    <div className="col-6 p-0" style={{height: props.heightHalf, color: `${props.serviceColor}`}}>
      <p
        className="text-center"
        style={{paddingTop: props.heightHalf * 1/8, fontSize: props.heightHalf/3}}>
        <span style={{fontSize: (props.heightHalf/3)*.61}}>$</span>{props.currentService.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
      <p
        className="text-center"
        style={{fontSize: props.heightHalf/5, lineHeight: .1}}>
        <span style={{fontSize: (props.heightHalf/5)*.61}}>/ $</span>{props.serviceSmartGoal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
    </div>
  )
}

export default CurrentSmartService