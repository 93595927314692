import React from 'react';
import '../styles/securityScreen.css'


const SecurityPrompt = (props) => {  
  return (
      <div className="text-center" style={{paddingTop: props.height /2}}>
      <h1><span className="text-uppercase securityH1">Check your phone. We just sent you a text.</span><br></br><span className="green">Please reply with the security code below<br></br>to unlock your dashboard.</span></h1>
      <p><span className="security">{props.randomCode}</span></p>
      </div>
  )
}

export default SecurityPrompt